const preloadVideo = (videoElement) => {
    videoElement.play();

    const b = setInterval(() => {
        if (videoElement.readyState >= 4) {
            videoElement.pause();
            videoElement.currentTime = 0;

            videoElement.dispatchEvent(new CustomEvent('video-ready', {
                detail: {
                    duration: videoElement.duration
                }
            }));

            clearInterval(b);
        }
    }, 500);
};

const createElementFromHTML = (htmlString) => {
    const div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    if (div.children.length > 1) {
        const itemsArray = [];
        Array.prototype.push.apply(itemsArray, div.children);

        return itemsArray;
    } else {
        return div.firstChild;
    }
};
const uniqueID = () => {
    const chr4 = () => {
        return Math.random().toString(16).slice(-4);
    };

    return chr4() + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() +
        '-' + chr4() + chr4() + chr4();
};

const loadYoutubeApi = () => {
    if (typeof YT === 'undefined') {
        const tag = document.createElement('script');
        tag.src = '//www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    }
};

const loadScript = (source, beforeEl, async = true, defer = true) => {
    return new Promise((resolve, reject) => {
        let script = document.createElement('script');
        const prior = beforeEl || document.getElementsByTagName('script')[0];

        script.async = async;
        script.defer = defer;

        function onloadHander (_, isAbort) {
            if (isAbort || !script.readyState || /loaded|complete/.test(script.readyState)) {
                script.onload = null;
                script.onreadystatechange = null;
                script = undefined;

                if (isAbort) {
                    const error = new Error('something went wrong');
                    reject(error);
                } else {
                    resolve(script);
                }
            }
        }

        script.onload = onloadHander;
        script.onreadystatechange = onloadHander;

        script.src = source;
        prior.parentNode.insertBefore(script, prior);
    });
};

const smoothScrollTo = (endX, endY, duration = 400, callback) => {
    const startX = window.scrollX || window.pageXOffset;
    const startY = window.scrollY || window.pageYOffset;
    const distanceX = endX - startX;
    const distanceY = endY - startY;
    const startTime = new Date().getTime();

    // Easing function
    const easeInOutQuart = (time, from, distance, duration) => {
        if ((time /= duration / 2) < 1) return distance / 2 * time * time * time * time + from;
        return -distance / 2 * ((time -= 2) * time * time * time - 2) + from;
    };

    const timer = window.setInterval(() => {
        const time = new Date().getTime() - startTime;
        const newX = easeInOutQuart(time, startX, distanceX, duration);
        const newY = easeInOutQuart(time, startY, distanceY, duration);

        if (time >= duration) {
            window.clearInterval(timer);
            if (typeof callback === 'function') {
                callback();
            }
        }
        window.scrollTo(newX, newY);
    }, 1000 / 60); // 60 fps
};

const getOffset = (el) => {
    const rect = el.getBoundingClientRect();
    const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    return {
        top: rect.top + scrollTop,
        left: rect.left + scrollLeft,
        bottom: rect.top + scrollTop + rect.height
    };
};

const dispatchForCode = (event, callback) => {
    let code;

    if (event.key !== undefined) {
        code = event.key;
    } else if (event.keyIdentifier !== undefined) {
        code = event.keyIdentifier;
    } else if (event.keyCode !== undefined) {
        code = event.keyCode;
    }

    if (typeof callback === 'function') {
        callback(code);
    }
};

export {
    createElementFromHTML,
    preloadVideo,
    uniqueID,
    loadYoutubeApi,
    loadScript,
    smoothScrollTo,
    getOffset,
    dispatchForCode
};
