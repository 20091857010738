import './media.scss';
import { loadYoutubeApi } from '../../utilities/js/helper';

require('../../assets/images/play.svg');

class Media {
    constructor (element) {
        this.$media = element;
        this.mediaType = this.$media.getAttribute('data-media');
        this.$video = null;
    }

    initialize () {
        if (this.mediaType === 'youtube') {
            this.$video = this.$media.querySelector('[data-youtube]');
            this.$thumbnail = this.$media.querySelector('img[data-media-img]');

            if (this.$thumbnail) {
                this.$thumbnail.src = this.$thumbnail.getAttribute('data-media-img');
            }

            import('../../utilities/js/youtube-player').then(({ YoutubePlayer }) => {
                this.initYoutubePlayer(YoutubePlayer);
            });
        }

        if (this.mediaType === 'video') {
            this.$video = this.$media.querySelector('video');
            this.initHTML5Player();
        }
    }

    initYoutubePlayer (YoutubePlayer) {
        if (this.$video) {
            const youtubeId = this.$video.getAttribute('data-youtube');

            if (youtubeId !== '') {
                const videoPlayer = new YoutubePlayer(this.$video, {
                    silent: !!this.$video.getAttribute('data-muted'),
                    fullscreen: !!this.$video.getAttribute('data-fullscreen'),
                    loop: !!this.$video.getAttribute('data-loop'),
                    root: this.$media,
                    id: youtubeId,
                    observe: true,
                    onCall: () => {
                        this.$media.classList.add('is--shown');
                    },
                    onStart: (videoId) => {
                        this.$media.classList.add('is--shown');
                    },
                    onEnd: (videoId) => {
                        if (videoPlayer.player.video.isMuted() === false) {
                            videoPlayer.destroy();
                            this.$media.classList.remove('is--shown');
                        }
                    },
                    onPause: (videoId, progress) => {
                        console.log('video pause');
                    }
                });
            }
        }
    }

    initHTML5Player () {
        const autoplay = !!this.$video.getAttribute('autoplay');
        const loop = !!this.$video.getAttribute('loop');

        if (autoplay) {
            this.$media.classList.add('is--playing');
            this.$video.load();
        }

        if (!loop) {
            this.$video.addEventListener('ended', () => {
                this.$media.classList.remove('is--playing');
            });
        }

        if (this.$media.classList.contains('has--poster')) {
            this.$media.querySelector('.media__play').addEventListener('click', () => {
                this.$media.classList.add('is--playing');
                this.$video.play();
            });
        }
    }

    setCookie (name, value, days) {
        let expires = '';
        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        }

        document.cookie = name + '=' + (value || '') + expires + '; path=/';
    }
}

export { Media };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $medias = $context.querySelectorAll('[data-media]');

        if ($medias.length > 0) {
            const waitForConsent = setInterval(() => {
                if (typeof OneTrust !== 'undefined') { // eslint-disable-line
                    if (OnetrustActiveGroups.indexOf('C0010') != -1) { // eslint-disable-line
                        if (typeof window.YT === 'undefined') {
                            loadYoutubeApi();

                            window.onYouTubeIframeAPIReady = () => {
                                $medias.forEach((media) => {
                                    const $media = new Media(media);
                                    const $placeholder = media.querySelector('.media__restriction');

                                    if ($placeholder) {
                                        $placeholder.remove();
                                    }

                                    $media.initialize();
                                });
                                clearInterval(waitForConsent);
                            };
                        } else {
                            $medias.forEach((media) => {
                                const $media = new Media(media);
                                const $placeholder = media.querySelector('.media__restriction');

                                if ($placeholder) {
                                    $placeholder.remove();
                                }
                                $media.initialize();
                            });
                            clearInterval(waitForConsent);
                        }
                    } else {
                        $medias.forEach((media) => {
                            const $placeholder = media.querySelector('.media__restriction');

                            const $button = media.querySelector('[data-restriction="remove"]');

                            $button.addEventListener('click', () => {
                                OneTrust.UpdateConsent("Category","C0010:1"); // eslint-disable-line
                                setTimeout(() => {
                                    location.reload();
                                }, 500);
                            });

                            if ($placeholder) {
                                $placeholder.classList.add('is--shown');
                            }
                        });
                        clearInterval(waitForConsent);
                    }
                }
            }, 300);
        }
    }
});
